//专利技术
import React, { useEffect } from "react";

function Patent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="patent">
      <div class="header-title">
        <div class="container">
          <h3>知识产权</h3>
        </div>
      </div>
      <div className="patent-table">
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-i.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-h.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-g.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-d.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-e.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-c.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-f.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-b.jpg"} />
        </div>
        <div className="patent-table-li">
          <img src={process.env.PUBLIC_URL + "/picture/pa-a.jpg"} />
        </div>
      </div>
    </div>
  );
}

export default Patent;
