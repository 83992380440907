import React, { useEffect, useRef, useState } from "react";
import "../../static/css/style.css";
import { useSelector } from "react-redux";

function WebIndexndex() {
  let { companyName } = useSelector((state) => state.item);
  const [portfolio, setPortfolio] = useState("eCommerce");
  const name = useRef("Show All");

  const queries = (a) => {
    setPortfolio(a);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <!-- loader --> */}
      {/* <div className="fakeLoader"></div> */}
      {/* <!-- end loader --> */}

      {/* <!-- home intro --> */}
      <div className="home-intro">
        <div className="container" style={{ width: "80%" }}>
          <div className="row">
            <div style={{ width: "50%" }}></div>
            <div className="col col-sm-12 col-md-6 col-12">
              {/* <h5>We are professional</h5> */}
              <h2>{companyName}</h2>
              <h6>
                互动内容自主研发·提供稳定可靠的系统集成定制服务 <br />
                专业的开发技术，产品团队让您的产品快速启动完美落地
              </h6>

              {/* <img
                style={{ float: "left" }}
                className="home-ew"
                src={process.env.PUBLIC_URL + "/picture/qywx.jpg"}
              /> */}
            </div>
            {/* <div className="col col-sm-12 col-md-6 col-12">
              <img src={process.env.PUBLIC_URL + "/picture/intro.jpg"} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- end end intro --> */}
      {/* <!-- features --> */}
      <div className="features bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content">
                <h3>互动软件</h3>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo
                  nesciunt modi, neque earum, error harum velit impedit totam
                  tempora!
                </p> */}
                <div className="list">
                  <div className="icon">
                    <i className="lni lni-checkmark"></i>
                  </div>
                  <div className="text">
                    <p>
                      交互性：互动软件的核心在于其交互性，即软件能够响应用户的操作和输入，并据此提供相应的反馈。这种交互性使得用户能够更直接、更自然地与软件进行交流，从而提高了软件的可用性和用户体验。
                    </p>
                  </div>
                </div>
                <div className="list">
                  <div className="icon">
                    <i className="lni lni-checkmark"></i>
                  </div>
                  <div className="text">
                    <p>
                      用户友好性：互动软件的设计通常强调用户友好性，即软件界面简洁明了，操作便捷，易于上手。这有助于降低用户的学习成本，提高用户的使用意愿和满意度。
                    </p>
                  </div>
                </div>
                <div className="list">
                  <div className="icon">
                    <i className="lni lni-checkmark"></i>
                  </div>
                  <div className="text">
                    <p>
                      个性化：互动软件能够根据用户的需求和偏好进行个性化定制，提供个性化的服务和内容。这种个性化不仅满足了用户的多样化需求，也增强了用户与软件之间的粘性。
                    </p>
                  </div>
                </div>
                <div className="list">
                  <div className="icon">
                    <i className="lni lni-checkmark"></i>
                  </div>
                  <div className="text">
                    <p>
                      智能性：随着人工智能技术的发展，互动软件越来越注重智能性。通过运用机器学习、自然语言处理等技术，软件能够更准确地理解用户的意图，提供更精准、更智能的交互体验。
                    </p>
                  </div>
                </div>
                {/* <a href="" className="button">
                  More Features
                </a> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <img
                  src={process.env.PUBLIC_URL + "/picture/features.jpg"}
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- features --> */}
      {/* <!-- what we do --> */}
      <div className="what-we-do section">
        <div className="title-section">
          <h3>服务内容</h3>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-graph"></i>
                <h5>互动软件定制开发</h5>
                <p>
                  提供富有创意的互动软件方案，交互界面设计，编程.根据客户提供的接口对软件进行扩展
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-stats-up"></i>
                <h5>物联网监测系统</h5>
                <p>精准、稳定、可靠、追述、专业分析、可视化交互、多级预警</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-slack"></i>
                <h5>AI软件定制开发</h5>
                <p>
                  通过一站式集成多个大型生成式语言模型，满足企业日常工作场景中对各类生成式AI的需求。
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-graph"></i>
                <h5>多媒体展厅解决方案</h5>
                <p>
                  提供整体展陈布局方案、可视化效果方案、内容设计、系统集成方案、施工方案等
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-stats-up"></i>
                <h5>互动硬件环境集成建设</h5>
                <p>
                  定制可控、高效的多媒体互动硬件环境，并将展示空间内所有的声光电、软硬件集成中央控制系统
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-slack"></i>
                <h5>三维仿真内容定制</h5>
                <p>仿真系统定制\3D动画定制</p>
              </div>
            </div>
            {/* <div class="col-md-4">
              <div class="content">
                <i class="lni lni-graph"></i>
                <h5>防汛排涝智慧调度系统</h5>
                <p>
                  洪涝数据监测、洪涝预报与预警、智慧调度决策支持、防汛排涝智慧调度APP
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-stats-up"></i>
                <h5>水利工程标准化管理系统</h5>
                <p>
                  基础信息展示、水利工程标准化数据资源、水利工程运行管理、水利工程监管、水利工程评价
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <i class="lni lni-slack"></i>
                <h5>供水监测系统</h5>
                <p>信息展示、实时监测、数据分析、监测预警</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- end what we do --> */}

      <div className="what-we-do section">
        <div className="title-section">
          <h3>部分产品</h3>
        </div>
        <div className="solution-table">
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-7.jpg"} />
            <div style={{ textAlign: "left" }}>
              <h4 style={{ fontWeight: "bold" }}>数字展厅 </h4>
              {/* <p style={{ marginBottom: "5px" }}>
                  <p>易于更新</p>
                  <p>可以随时进行更新和调整，保持内容的新鲜和与时俱进。</p>
                </p>
                <p>可定制化</p> */}
              <p>
                字展厅又叫做数字化展厅、多媒体数字化展厅等，是指以多媒体和数字化技术作为展示技术，使用最新的影视动画技术，结合独到的图形数字和多媒体技术，以各类新颖的技术吸引参观者，实现人机交互方式的展厅形式。
              </p>
            </div>
          </div>
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-8.jpg"} />
            <div style={{ textAlign: "left" }}>
              <h4 style={{ fontWeight: "bold" }}>多媒体科技馆 </h4>
              {/*<p style={{ marginBottom: "5px" }}>
                  <p>增强教育功能</p>
                  <p>
                    通过互动多媒体，可以设计各种教育项目，帮助观众更深入地了解作品背后的故事和知识。
                  </p>
                </p>
                <p>扩大宣传效果</p> */}
              <p>
                多媒体科技馆是通过参与、体验、互动性的展品及辅助性展示手段，以激发科学兴趣、启迪科学观念为目的，鼓励公众动手探索实践。
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="what-we-do section">
        <div className="title-section">
          <h3>客户案例</h3>
          <div className="usercase-water-table solution-table">
            <div className="usercase-interaction-table-li">
              <img
                src={process.env.PUBLIC_URL + "/picture/uca-aa.jpg"}
                alt="互动投影系统"
              />
            </div>
            <div className="usercase-interaction-table-li">
              <img
                src={process.env.PUBLIC_URL + "/picture/uca-bb.jpg"}
                alt="多媒体互动展示"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <!-- portfolio --> */}
      {/* <div className="portfolio section">
        <div className="container">
          <div className="box-content">
            <div className="title-section">
              <h3>合作伙伴</h3>
            </div>
            <div className="portfolio-table">
              <div className="portfolio-table-li">
                <img src={process.env.PUBLIC_URL + "/picture/po-a.jpg"} />
                <h5>中国水利水电科学研究院</h5>
              </div>
              <div className="portfolio-table-li">
                <img src={process.env.PUBLIC_URL + "/picture/po-d.jpg"} />
                <h5>上海市农科院</h5>
              </div>
              <div className="portfolio-table-li">
                <img src={process.env.PUBLIC_URL + "/picture/po-b.jpg"} />
                <h5>清华大学</h5>
              </div>
              <div className="portfolio-table-li">
                <img src={process.env.PUBLIC_URL + "/picture/po-c.jpg"} />
                <h5>中国人民大学解放军海军工程大学</h5>
              </div>
              <div className="portfolio-table-li">
                <img src={process.env.PUBLIC_URL + "/picture/po-e.png"} />
                <h5>东营市水务局</h5>
              </div>
              <div className="portfolio-table-li"></div>
            </div> */}
      {/* <div className="portfolio-filter-menu">
              <ul>
                <li
                  data-filter="all"
                  // onClick={queries("Show All")}
                  className="Show All">
                  <span>Show All</span>
                </li>
                <li data-filter="1">
                  <span>eCommerce</span>
                </li>
                <li data-filter="2">
                  <span>Landing</span>
                </li>
                <li data-filter="3">
                  <span>UI Kit</span>
                </li>
              </ul>
            </div> */}
      {/* <div className="row no-gutters filtr-container">
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="3">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works1.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works1.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="1">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works2.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works2.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="2, 1">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works5.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works5.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="3">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works4.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works4.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="1, 2">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works3.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works3.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="3, 1">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works6.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works6.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="3">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works7.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works7.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="1, 2">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works8.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works8.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 col-xs-12 filtr-item"
                data-category="3, 1">
                <div className="content-image">
                  <img
                    src={process.env.PUBLIC_URL + "/picture/works9.jpg"}
                    alt=""
                  />
                  <div className="image-overlay"></div>
                  <div className="portfolio-caption">
                    <div className="title">
                      <h4>ClassNameic Minimalist</h4>
                      <span>Graphic Design</span>
                    </div>
                    <div className="subtitle">
                      <ul>
                        <li>
                          <a href="">
                            <i className="lni lni-link"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              process.env.PUBLIC_URL + "/picture/works9.jpg"
                            }
                            className="portfolio-popup">
                            <i className="lni lni-move"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
      {/* </div>
        </div> */}
      {/* <div className="container">
          <div className="cta">
            <div className="row">
              <div className="col-md-9">
                <div className="content">
                  <h2>Want to see more of our work?</h2>
                </div>
              </div>
              <div className="col-md-3">
                <a href="" className="button">
                  View More
                </a>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      {/* <!-- end portfolio --> */}

      {/* <!-- testimonial --> */}
      {/* <div className="testimonial section bg-grey">
        <div className="container">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel">
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <span>
                  <i className="lni lni-quotation"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Aspernatur molestiae totam corporis asperiores, id rerum quod
                  officia, dolor ducimus earum doloremque ipsa vel, debitis
                  dolorem, laudantium! In veritatis a, atque!
                </p>
                <h5>Jamal Ali</h5>
              </div>
              <div className="carousel-item">
                <span>
                  <i className="lni lni-quotation"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Aspernatur molestiae totam corporis asperiores, id rerum quod
                  officia, dolor ducimus earum doloremque ipsa vel, debitis
                  dolorem, laudantium! In veritatis a, atque!
                </p>
                <h5>Nabil Dirar</h5>
              </div>
              <div className="carousel-item">
                <span>
                  <i className="lni lni-quotation"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Aspernatur molestiae totam corporis asperiores, id rerum quod
                  officia, dolor ducimus earum doloremque ipsa vel, debitis
                  dolorem, laudantium! In veritatis a, atque!
                </p>
                <h5>Moussa Silla</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- end testimonial --> */}
    </div>
  );
}

export default WebIndexndex;
