import { configureStore } from "@reduxjs/toolkit";
import WebReducer from "./slices/webslice";

const appStore = configureStore({
  reducer: {
    item: WebReducer,
  },
});

export default appStore;
