import { createSlice } from "@reduxjs/toolkit";
// import { ApiRequest } from "../../api/api";
import { React, Component, UseEffect, useState } from "react";
// import log from "video.js/dist/types/utils/log";

const WebSlice = createSlice({
  name: "content",
  initialState: {
    /**
     * 公司名称
     */
    companyName: "上海图慧科技有限公司",
  },
  reducers: {},

  extraReducers: (builder) => {
    /**
     * 内容管理数据
     */
  },
});

// export const { filter } = WebSlice.actions;
export default WebSlice.reducer;
