import React, { useEffect } from "react";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <!-- header-title --> */}
      <div class="header-title">
        <div class="container">
          <h3>联系我们</h3>
        </div>
      </div>
      {/* <!-- end header-title --> */}

      {/* <!-- contact --> */}
      <div class="contact section">
        <div class="container">
          <div
            class="row"
            style={{ justifyContent: "center", marginBottom: "50px" }}>
            <div class="col-md-4 col-sm-12 col-12">
              <ul>
                <li>
                  <i class="fa fa-envelope"></i> 812901872@qq.com
                </li>
                <li>
                  <i class="fa fa-phone"></i> 13866601398
                </li>
                <li class="map">
                  <i class="fa fa-map-marker"></i>
                  <p>
                    上海市闵行区莲花南路 1929号
                    <br />
                    飞奥科创园
                  </p>
                </li>
                <li>
                  <img
                    style={{ float: "left" }}
                    className="home-ew"
                    src={process.env.PUBLIC_URL + "/picture/qywx.jpg"}
                  />
                </li>
              </ul>
            </div>
            {/* <div class="col-md-8 col-sm-12 col-12">
              <form action="#">
                <div class="row">
                  <div class="col">
                    <input type="text" placeholder="姓名" />
                  </div>
                  <div class="col">
                    <input type="email" placeholder="邮箱" />
                  </div>
                </div>
                <input type="text" placeholder="联系电话" />
                <textarea cols="30" rows="10" placeholder="你的信息"></textarea>
                <button class="button">提交</button>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
