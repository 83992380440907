import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
// import UserInfo from "../login/userinfo";

// import {
//   ChartPie,
//   DatabaseSearch,
//   MonitorOne,
//   TableFile,
//   PeopleSearchOne,
//   RemoteControl,
// } from "@icon-park/react";
// import "@icon-park/react/styles/index.css";

// import "./navigatetemplate.css";
const { Sider, Header, Content } = Layout;

const NavigateTemplate = (props) => {
  const { logo, title } = props;
  const [menucollapsed, setMenuCollapsed] = useState(true);
  const navigate = useNavigate();
  const [current, setCurrent] = useState("0");

  const menuItems = [
    {
      key: "0",
      label: "项目管理",
      path: "/index",
    },
    {
      key: "1",
      label: "内容管理",
      path: "/about",
    },
    {
      key: "2",
      label: "模板管理",
      path: "/template",
    },
    {
      key: "3",
      label: "模板项管理",
      path: "/templateitem",
    },
  ];

  //菜单选择事件
  const onMenuItemClick = (item) => {
    // let path = item.props.path;

    if (item) {
      navigate(item);
    }
  };

  return (
    <Layout className="layout_container">
      {/* <Header className="header" style={{ padding: 0 }}> */}
      <nav class={"navbar navbar-expand-md fixed-top navbar-fixed"}>
        <div class="container">
          <a href="" class="navbar-brand"></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <Link to="/" class="nav-link">
                  主页
                </Link>
              </li>
              {/* <li class="nav-item">
                <Link to="/about" class="nav-link">
                  关于我们
                </Link>
              </li> */}
              <li class="nav-item">
                <Link to="/solution" class="nav-link">
                  产品特点
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/usercase" class="nav-link">
                  客户案例
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/patent" class="nav-link">
                  知识产权
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/contactus" class="nav-link">
                  联系方式
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* </Header> */}

      <Layout style={{ display: "flex" }}>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>

      {/* <!-- footer --> */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-8 col-12">
              <h6>上海图慧科技有限公司</h6>
              <p>上海市闵行区莲花南路1929号飞奥科创园</p>
            </div>
            <div className="col-md-2 col-sm-4 col-12">
              <h5>关于我们</h5>
              <ul>
                {/* <li>
                  <Link to="/about">关于我们</Link>
                </li> */}
                <li>
                  <Link to="/solution">产品特点</Link>
                </li>
                <li>
                  <Link to="/contactus">联系方式</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-6 col-12">
              <h5>客户案例</h5>
              <ul>
                <li>
                  <Link to="/usercase">客户案例</Link>
                </li>
                {/* <li>
                  <a href="">Twitter</a>
                </li>
                <li>
                  <a href="">Instagram</a>
                </li> */}
              </ul>
            </div>
            <div className="col-md-2 col-sm-6 col-12">
              <img
                style={{ float: "left" }}
                className="home-ew"
                src={process.env.PUBLIC_URL + "/picture/qywx.jpg"}
              />
              {/* <h5>知识产权</h5>
              <ul>
                <li>
                  <Link to="/patent">知识产权</Link>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- end footer --> */}
      {/* <!-- footer bottom --> */}
      <div className="footer-bottom">
        <div className="container">
          <p>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="https://beian.miit.gov.cn">
              沪ICP备19024967号-1
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NavigateTemplate;
