import "./static/css/style.css";
import "./static/css/bootstrap.min.css";
import "./static/css/fontawesome.min.css";
import "./static/css/LineIcons.css";
import "./static/css/fakeLoader.min.css";
import "./static/css/magnific-popup.css";

import { Route, Routes, Navigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import HomePage from "./pages/home/home";
import WebIndex from "./pages/webIndex/webIndex";
// import WebAbout from "./pages/webAbout/webAbout";
import Patent from "./pages/patent/patent";
import UserCase from "./pages/userCase/userCase";
import ContactUs from "./pages/contactUs/contactUs";
import Solution from "./pages/solution/solution";
import NavigateTemplate from "./pages/templates/navigatetemplate";

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route element={<NavigateTemplate />}>
          <Route path="/index" element={<WebIndex />} />
          {/* <Route path="/about" element={<WebAbout />} /> */}
          <Route path="/patent" element={<Patent />} />
          <Route path="/usercase" element={<UserCase />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/solution" element={<Solution />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
