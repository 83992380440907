import React, { useEffect } from "react";

function UserCase() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="usercase">
      <div class="header-title">
        <div class="container">
          <h3>客户案例</h3>
        </div>
      </div>

      <div className="usercase-table usercase-bot">
        <h3>互动软件</h3>
        <div className="usercase-water-table">
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-aa.jpg"}
              alt="互动投影系统"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-bb.jpg"}
              alt="多媒体互动展示"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-cc.jpg"}
              alt="多媒体展示系统"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-dd.jpg"}
              alt="虚拟现实(VR)体验"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-ee.jpg"}
              alt="增强现实(AR)展示"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-ff.jpg"}
              alt="互动多媒体AI软件开发"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-gg.jpg"}
              alt="互动多媒体行业"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-hh.jpg"}
              alt="数字展示技术"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-ii.jpg"}
              alt="数字营销解决方案"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-jj.jpg"}
              alt="互动媒体技术"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img
              src={process.env.PUBLIC_URL + "/picture/uca-kk.jpg"}
              alt="多媒体软件开发"
            />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-ll.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-mm.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-nn.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-oo.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-pp.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-qq.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
          <div className="usercase-interaction-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/uca-rr.jpg"} />
            {/* <h5>防汛排涝智慧调度系统</h5> */}
          </div>
        </div>
      </div>

      <div className="usercase-bg">
        <div className="usercase-table">
          <h3>智慧水务</h3>
          <div className="usercase-water-table" style={{ height: "50vh" }}>
            <div className="usercase-water-table-li">
              <img src={process.env.PUBLIC_URL + "/picture/uca-a.jpg"} />
              <h5>防汛排涝智慧调度系统</h5>
            </div>
            <div className="usercase-water-table-li">
              <img src={process.env.PUBLIC_URL + "/picture/uca-b.jpg"} />
              <h5>水利工程标准化管理系统</h5>
            </div>
            <div className="usercase-water-table-li">
              <img src={process.env.PUBLIC_URL + "/picture/uca-c.jpg"} />
              <h5>供水监测系统</h5>
            </div>
            {/* <div className="usercase-water-table-li">
              <img src={process.env.PUBLIC_URL + "/picture/uca-d.jpg"} />
              <h5>3D供排水管网管理系统</h5>
            </div>
            <div className="usercase-water-table-li">
              <img src={process.env.PUBLIC_URL + "/picture/uca-e.jpg"} />
              <h5>河湖档案展示APP</h5>
            </div>
            <div className="usercase-water-table-li"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCase;
