import React, { useEffect } from "react";

function Solution() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <!-- header-title --> */}
      <div class="header-title">
        <div class="container">
          <h3>产品特点</h3>
        </div>
      </div>
      {/* <!-- end header-title --> */}

      {/* <!-- services --> */}
      {/* <div class="services bg-grey">
        <div class="container">
          <div class="row mb-services">
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fab fa-drupal"></i>
                <h5>Drupal</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fab fa-wordpress"></i>
                <h5>WordPress</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fab fa-magento"></i>
                <h5>Magento</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fab fa-facebook"></i>
                <h5>Facebook Ads</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fa fa-video"></i>
                <h5>Audio Editing</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
            <div class="col col-md-4 col-sm-6 col-12">
              <div class="content">
                <i class="fa fa-cog"></i>
                <h5>Maintenance</h5>
                <p class="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus quisquam maxime eos, culpa minus placeat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- end services --> */}

      <div className="solution-table">
        <h3 className="solution-title">互动多媒体</h3>
        <div className="solution-table">
          {/* <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-1.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>提升体验感 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>互动性强</p>
                <p>
                  多媒体技术如触摸屏、虚拟现实（VR）、AI、增强现实（AR）等，让观众可以通过互动参与到其中，而不仅仅是被动观看。
                </p>
              </p>
              <p>视觉冲击力</p>
              <p>
                大屏幕投影、全息成像等技术能够呈现震撼的视觉效果，吸引观众注意力，增强记忆度和参与感。
              </p>
            </div>
          </div>
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-2.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>信息传达更有效 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>多感官体验</p>
                <p>
                  结合声音、图像、视频和文字等多种信息传递方式，能够更全面、深入地传达信息，让观众更容易理解和记忆。
                </p>
              </p>
              <p>动态展示</p>
              <p>
                相较于传统静态展览，动态展示能够更生动地呈现内容，比如历史事件的再现、科学实验的模拟等，让内容更具生命力。
              </p>
            </div>
          </div> */}
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-3.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>灵活性和可更新性 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>易于更新</p>
                <p>可以随时进行更新和调整，保持内容的新鲜和与时俱进。</p>
              </p>
              <p>可定制化</p>
              <p>
                可以根据不同的主题和需求，快速调整内容和形式，具备很高的灵活性和适应性。
              </p>
            </div>
          </div>
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-4.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>提高教育和宣传效果 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>增强教育功能</p>
                <p>
                  通过互动多媒体，可以设计各种教育项目，帮助观众更深入地了解作品背后的故事和知识。
                </p>
              </p>
              <p>扩大宣传效果</p>
              <p>
                互动多媒体的创新和独特性能够吸引更多的媒体关注和观众的兴趣，通过社交媒体和口碑传播，提高其知名度和影响力。
              </p>
            </div>
          </div>
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-5.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>数据收集与分析 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>观众行为分析</p>
                <p>
                  通过多媒体设备，可以收集观众的行为数据，如停留时间、互动频次等，为优化和改进提供数据支持。
                </p>
              </p>
              <p>精准营销</p>
              <p>
                根据观众的数据分析，可以进行更精准的营销和推广活动，提高效果和观众满意度。
              </p>
            </div>
          </div>
          <div className="solution-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-mt-6.jpg"} />
            <div>
              <h4 style={{ fontWeight: "bold" }}>环保和可持续性 </h4>
              <p style={{ marginBottom: "5px" }}>
                <p>减少物理材料消耗</p>
                <p>
                  互动多媒体可以减少纸张、塑料等传统材料的使用，有助于环保和资源节约。{" "}
                </p>
              </p>
              <p>可持续性</p>
              <p>维护和更新成本较低，长期来看更加经济和可持续。</p>
            </div>
          </div>
        </div>
      </div>

      <div className="solution-table">
        <h3 className="solution-title">防汛排涝智慧调度系统</h3>
        <div className="solution-A-table">
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-fx-d.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-fx-c.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-fx-a.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-fx-b.jpg"} />
          </div>
        </div>
      </div>
      {/* <div className="solution-table usercase-bg">
        <h3 className="solution-title">水利工程方案</h3>
        <div className="solution-A-table">
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-sl-c.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-sl-a.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-sl-b.jpg"} />
          </div>

          <div className="solution-A-table-li"></div>
        </div>
      </div> */}
      {/* <div className="solution-table">
        <h3 className="solution-title">供水监测方案</h3>
        <div className="solution-A-table">
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-gs-a.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-gs-b.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-gs-c.jpg"} />
          </div>
          <div className="solution-A-table-li">
            <img src={process.env.PUBLIC_URL + "/picture/so-gs-d.jpg"} />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Solution;
